import React, { useState } from 'react';
import BlackAndWhiteBen from './black_and_white_benjamin.jpg';
import ColourBen from './colour_benjamin.jpg';
import { ReactComponent as PlayButton} from './play_button.svg';
import { ReactComponent as TrebleClefIcon} from './treble_clef.svg';
import { ReactComponent as SynthesisIcon} from './synth.svg';
import { ReactComponent as ChiptunesIcon} from './8_bit_note.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faCopyright, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import './App.css';
import videojs, { VideoJsPlayer } from 'video.js'
import 'video.js/dist/video-js.css'

type ContentBoxKeys = "landing" | "sc" |"styles" | "about" | "contact"

type ContentBox = {[key in ContentBoxKeys]: HTMLElement};

const App: React.FC = () => {

  const contentBox : ContentBox = {
    landing: React.createRef<HTMLElement>().current, 
    sc: React.createRef<HTMLElement>().current,
    styles: React.createRef<HTMLElement>().current, 
    about: React.createRef<HTMLElement>().current, 
    contact: React.createRef<HTMLElement>().current
  };
  
  const musicalIcons = React.useRef<HTMLElement[]>([]);

  const hamburgerIcon = React.useRef<HTMLElement>();

  let musicPlayers : VideoJsPlayer[] = [];

  const fadeInClassName = "fade-in-icon";

  const [shouldTriggerIconAnimation, setTriggerIconAnimation] = useState(false);

  const [isHamburgerToggled, setHamburgerToggle] = useState(false);

  window.onscroll = () => {
    if (contentBox.styles) {
      let bounds = contentBox.styles.getBoundingClientRect();
      if (!shouldTriggerIconAnimation && 
        bounds.top >= 0 && bounds.left >= 0 && bounds.bottom  <= window.innerHeight && bounds.right <= window.innerWidth) {
        setTriggerIconAnimation(true);
        triggerIconAnimation();
      }
    }
  }

  React.useEffect(() => {
  (window as any).SC.Widget(document.querySelector('iframe')).bind((window as any).SC.Widget.Events.PLAY, () => {
    let musicPlayer = musicPlayers.find(x => !x.paused());
    if (musicPlayer) {
      musicPlayer.pause();
    }
  });
  });

  async function triggerIconAnimation() {
    for (const icon of musicalIcons.current) {
      icon.className += ` ${fadeInClassName}`;
      await new Promise(res => setTimeout(res, 100));
    }
  }

  function navItemScrollTo(element: HTMLElement, behavior?: ScrollBehavior, block?: ScrollLogicalPosition) {
    element.scrollIntoView({behavior, block});
  }

  function checkPlayers(playerToPlay: VideoJsPlayer) {
    for (const player of musicPlayers) {
      if (player !== playerToPlay) {
        player.pause();
      }
    }
    (window as any).SC.Widget(document.querySelector('iframe')).pause();
  }

  function loadNavItems() {
    return (
      <>
        <span className="navigation-item" onClick={() => navItemScrollTo(contentBox.sc, "smooth")}>
          Listen
        </span>
        <span className="navigation-item" onClick={() => navItemScrollTo(contentBox.styles, "smooth", "center")}>
          Music styles
        </span>
        <span className="navigation-item" onClick={() => navItemScrollTo(contentBox.about, "smooth", "start")}>
          About
          </span>
        <span className="navigation-item" onClick={() => navItemScrollTo(contentBox.contact, "smooth")}>
          Contact
        </span>
      </>
    );
  }

  function handleIfHamburgerIconIsOpen() {
    if (isHamburgerToggled) {
      setHamburgerToggle(!isHamburgerToggled);
    }
  }

  const isMobile = window.matchMedia("only screen and (min-width: 320px) and (max-width: 991px)").matches;

  return (
    <div onClick={() => handleIfHamburgerIconIsOpen()} className="app responsive-font-size">
      <header className="header-base">
        <span><TrebleClefIcon className="header-treble-clef-icon"/></span>
        <span className="header-logo-typography">BEN SHABO</span>
        {
          !isMobile ? 
          <span className="navigation-row-base">
            {loadNavItems()}
          </span>
          :
          <span className="hamburger-icon vertical-centering">
              <span ref={(elem) => hamburgerIcon.current = elem} onClick={() => setHamburgerToggle(!isHamburgerToggled)}>
                <FontAwesomeIcon icon={faBars}/>
              </span>
              {
                isHamburgerToggled &&
                <div className="hamburger-nav-pane">
                  {loadNavItems()}
                </div>
              }
          </span>
        }
      </header>
      <div ref={(elem: HTMLDivElement) => contentBox.landing = elem} className="vertical-centering box-height-by-viewport landing-photo" style={{backgroundImage: `url(${BlackAndWhiteBen})`}}>
        <section>
          <p className="landing-name-title">BEN SHABO</p>
          <p className="landing-composer-title">Video game composer</p>
          {
            !isMobile &&
            <p>
              <PlayButton className="play-button" onClick={() => {
                contentBox.sc.scrollIntoView({behavior: "smooth"});
                (window as any).SC.Widget(document.querySelector('iframe')).toggle();
              }}/>
            </p>
          }
        </section>
        <section>
          <p className="double-chevron-button">
            <span onClick={() => contentBox.sc.scrollIntoView({behavior: "smooth"})}>
              <FontAwesomeIcon size={isMobile ? "2x" : "lg"} icon={faAngleDoubleDown}/>
            </span>
          </p>
        </section>
      </div>
      <div ref={(elem: HTMLDivElement) => contentBox.sc = elem} className="smaller-box-height-by-viewport vertical-centering sc-base">
      <h1 className="header-one-styles">Listen</h1>
        <iframe 
          width="800" 
          height="600" 
          title="ben-shabo-soundcloud"
          scrolling="no"
          allow="autoplay" 
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/290622291&color=%23ff5500&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=true">
        </iframe>
        <div className="soundcloud-blurb">
          <p onClick={() => contentBox.styles.scrollIntoView({behavior: "smooth", block: "center"})} className="anchor-text">
            See styles of music
            <FontAwesomeIcon style={{padding: "10px", verticalAlign: "middle"}} icon={faAngleDoubleDown}/>
          </p>
        </div>
      </div>
      <section ref={(elem: HTMLDivElement) => contentBox.styles = elem} className="smaller-box-height-by-viewport vertical-centering" style={{justifyContent: "space-evenly"}}>
        <h1 className="header-one-styles">Styles</h1>
        <div className="musical-icons-row">
          <article ref={(elem) => {if (elem != null) musicalIcons.current = [...musicalIcons.current, elem]}} className="vertical-centering hidden-icon">
            <TrebleClefIcon className="musical-icon"/>
            <div className="musical-icon-blurb">Orchestration</div>
            <div data-vjs-player>
              <audio ref={(elem) => {
                    if (elem != null) {
                      let player = videojs(elem);
                      player.on("play", () => checkPlayers(player))
                      musicPlayers = [...musicPlayers, player];
                    }
                }} className="video-js vjs-default-skin audio-player" controls preload="auto">
                <source src="https://s3-ap-southeast-2.amazonaws.com/www.benshabocomposer.com/Forsaken+Village.mp3" type='audio/mp3'/>
              </audio>
            </div> 
          </article>
          <article ref={(elem) => {if (elem != null) musicalIcons.current = [...musicalIcons.current, elem]}} className="vertical-centering hidden-icon">
            <SynthesisIcon className="musical-icon"/>
            <div className="musical-icon-blurb">Synthestration</div>
            <div data-vjs-player>
              <audio ref={(elem) => {
                  if (elem != null) {
                    let player = videojs(elem);
                    player.on("play", () => checkPlayers(player))
                    musicPlayers = [...musicPlayers, player];
                  }
                }} className="video-js vjs-default-skin audio-player" controls preload="auto">
                <source src="https://s3-ap-southeast-2.amazonaws.com/www.benshabocomposer.com/Enter+the+Dark+Realm.mp3" type='audio/mp3'/>
              </audio>
            </div>
          </article>
          <article ref={(elem) => {if (elem != null) musicalIcons.current = [...musicalIcons.current, elem]}} className="vertical-centering hidden-icon">
            <ChiptunesIcon className="musical-icon"/>
            <div className="musical-icon-blurb">Chiptunes</div>
            <div data-vjs-player>
              <audio ref={(elem) => {
                if (elem != null) {
                  let player = videojs(elem);
                  player.on("play", () => checkPlayers(player))
                  musicPlayers = [...musicPlayers, player];
                }
              }} className="video-js vjs-default-skin audio-player" controls preload="auto">
                <source src="https://s3-ap-southeast-2.amazonaws.com/www.benshabocomposer.com/Boss+Battle+%232.mp3" type='audio/mp3'/>
              </audio>
            </div>
          </article>
        </div>
      </section>
      <section ref={(elem: HTMLDivElement) => contentBox.about = elem} className="smaller-box-height-by-viewport white-bg-black-font">
        <article className="about-benjamin">
          <h1 style={{paddingBottom: "10px"}} className="header-one-styles">About</h1>
          <p className="about-benjamin-paragraphs">
            Ben grew up with video games in the late 90's and has always found something special and captivating about the music.
            Appreciating huge scores and chiptunes written by established composers, Ben decided he wanted to make his own contribution.
          </p>
          <p className="about-benjamin-paragraphs">
            His love for games has spawned an entire portfolio of self-composed tunes specially designed for video games. 
            He writes and orchestrates his own video game music, sometimes incorporating elements of music synthesis.             
            In addition, Ben's work also includes writing chiptunes.
          </p>
          <p className="about-benjamin-paragraphs">
            Ben believes the key to a good game track is to make it short and memorable. His creativity is inspired from composers who write catchy melodies with loopable and lively tracks.
            As such, a lot of his influences flow from <i>The Legend Of Zelda</i> series and the <i>Super Mario</i> series as well as many other memorable indie games like <i>Ori and the Blind Forest</i>. 
          </p>
          <p className="about-benjamin-paragraphs">
            When Ben is not writing music, he loves to spend his time in software development for video games and other applications. He is able to speak the "game dev language" to communicate
            and bridge the gap between composition and game development.
          </p>
          <p className="anchor-text" onClick={() => contentBox.sc.scrollIntoView({behavior: "smooth"})}>
            Listen to music
          </p>
        </article>
        <aside className="about-benjamin-image" style={{backgroundImage: `url(${ColourBen})`}}/>
      </section>
      <section ref={(elem: HTMLDivElement) => contentBox.contact = elem} className="contact-base vertical-centering">
        <h1 className="header-one-styles">Want to get in touch?</h1>
        <div className="contact-items">
          <div onClick={() => window.location.replace("https://twitter.com/benshab0")}>
            <FontAwesomeIcon className="contact-icons" style={isMobile ? {width: "80px", height: "80px"} : {width: "60px", height: "60px"}} icon={faTwitterSquare}/>
          </div>
          <div onClick={() => window.location.replace("mailto:ben.shabo01@gmail.com")}>
            <FontAwesomeIcon className="contact-icons" style={isMobile ? {width: "80px", height: "80px"} : {width: "60px", height: "60px"}} icon={faEnvelope}/>
          </div>
        </div>
      </section>
      <footer className="footer-base">
        <span style={{paddingRight: "20px"}}>
          <FontAwesomeIcon style={{color: "black", paddingRight: "5px"}} icon={faCopyright}/> {new Date().getFullYear()} Ben Shabo, Designed by Ben Shabo
        </span>
      </footer>
    </div>
  );
}

export default App;
